<template>
  <el-dialog
    title="搜尋推薦設定"
    :visible="true"
    width="630px"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <BaseElForm label-position="left" label-width="200px">
      <BaseElFormItem label="活動/類別推薦">
        <BaseElSelect
          v-model="selected"
          value-key="id"
          multiple
          :multiple-limit="5"
          collapse-tags
          placeholder="選擇活動或類別"
        >
          <BaseElSelectOption
            v-for="(option, index) in options"
            :key="index"
            :label="option.name"
            :value="option"
          />
        </BaseElSelect>
      </BaseElFormItem>
    </BaseElForm>

    <div slot="footer">
      <BaseElButton plain @click="cancel">取消</BaseElButton>
      <BaseElButton type="primary" @click="updateNavConfig">儲存</BaseElButton>
    </div>
  </el-dialog>
</template>

<script>
import { UpdateNavConfig } from '@/api/ecommerce/navSetting'
import { mapGetters } from 'vuex'
import { map, forEach, find, omitBy } from 'lodash'

export default {
  name: 'SearchRecommandDialog',
  props: ['activitys', 'categorys', 'trendConfig', 'navConfig'],
  computed: {
    ...mapGetters(['shop']),
    trendList () {
      const data = []
      forEach(this.selected, i => {
        if (i.type === 'activity') {
          data.push({
            type: 'ecommerceActivity',
            ecommerceActivityId: i.id,
          })
        }
        if (i.type === 'category') {
          data.push({
            type: 'ecommerceCategory',
            ecommerceCategoryId: i.id,
          })
        }
      })
      return data
    },
  },
  data: () => ({
    selected: [],
    // activitys: [],
    // categorys: [],
    options: [],
  }),
  async mounted () {
    const acts = map(this.activitys, i => ({
      type: 'activity',
      name: i.name,
      id: i.id,
    }))
    const cates = map(this.categorys, i => ({
      type: 'category',
      name: i.name,
      id: i.id,
    }))
    this.options.push(...acts)
    this.options.push(...cates)

    await this.syncConfig()
  },
  methods: {
    cancel () {
      this.$emit('close')
    },

    async updateNavConfig () {
      const navs = map(this.navConfig, item => omitBy(item, i => !i))
      if (!navs.length) {
        navs.push({
          type: 'allProduct',
        })
      }
      const [res, err] = await UpdateNavConfig({
        shopId: this.shop,
        trend: this.trendList,
        navigation: navs,
      })
      if (err) return this.$message.error(err)
      this.$message.success('更新成功！')
      this.$emit('refresh')
      this.$emit('close')
    },

    syncConfig () {
      const config = this.trendConfig
      forEach(config, i => {
        if (i.type === 'ecommerceActivity') {
          const exist = find(this.activitys, act => act.id === i.ecommerceActivityId)
          this.selected.push({
            type: 'activity',
            id: exist.id,
            name: exist.name,
          })
        }
        if (i.type === 'ecommerceCategory') {
          const exist = find(this.categorys, cate => cate.id === i.ecommerceCategoryId)
          this.selected.push({
            type: 'category',
            id: exist.id,
            name: exist.name,
          })
        }
      })
    },
  },
}
</script>

<style lang="postcss" scoped>
::v-deep .el-select {
  @apply !w-full;
}
::v-deep .el-input {
  @apply !w-full;
}
</style>
