<template>
  <div class="search-recommand card-container">
    <SectionTitle title="搜尋推薦設定" hint="活動/類別推薦最多共五個。" @edit="openDialog = true" />
    <BaseElForm label-width="130px" label-position="left">
      <BaseElFormItem label="活動/類別推薦">
        <div class="font-normal">
          {{ listTrend() }}
        </div>
      </BaseElFormItem>
    </BaseElForm>

    <SearchRecommandDialog
      v-if="openDialog"
      :trendConfig="trendConfig"
      :navConfig="navConfig"
      :activitys="activitys"
      :categorys="categorys"
      @refresh="$emit('refresh')"
      @close="openDialog = false"
    />
  </div>
</template>

<script>
import SearchRecommandDialog from './SearchRecommandDialog.vue'
import SectionTitle from '@/components/Title/SectionTitle.vue'
import { map, forEach, find } from 'lodash'

export default {
  name: 'SearchRecommand',
  components: { SectionTitle, SearchRecommandDialog },
  props: ['activitys', 'categorys', 'trendConfig', 'navConfig'],
  data: () => ({
    openDialog: false,
  }),
  methods: {
    listTrend () {
      const names = []
      forEach(this.trendConfig, i => {
        if (i.type === 'ecommerceActivity') {
          const exist = find(this.activitys, act => act.id === i.ecommerceActivityId)
          if (exist)names.push(exist.name)
        }
        if (i.type === 'ecommerceCategory') {
          const exist = find(this.categorys, cate => cate.id === i.ecommerceCategoryId)
          if (exist)names.push(exist.name)
        }
      })

      if (!names.length) return ''
      return names.join('、')
    },
  },
}
</script>

<style lang="postcss" scoped>
.search-recommand {
  @apply px-[33px] py-[24px];
}
</style>
