<template>
  <div class="test">
    <BaseElFormItem>
      <BaseElSelect v-model="selectOption" clearable placeholder="請選擇功能">
        <BaseElSelectOption
          v-for="option in options"
          :key="option.type"
          :label="option.label"
          :value="option.type"
        />
      </BaseElSelect>
    </BaseElFormItem>

    <template v-if="selectOption === 'link'">
      <BaseElFormItem :prop="`${objKey}.name`">
        <BaseElInput v-model="linkName" placeholder="請輸入連結名稱" />
      </BaseElFormItem>

      <BaseElFormItem :prop="`${objKey}.url`">
        <BaseElInput v-model="linkUrl" placeholder="請輸入連結網址" />
      </BaseElFormItem>
    </template>

    <template v-if="selectOption === 'ecommerceActivity'">
      <BaseElFormItem :prop="`${objKey}.ecommerceActivityId`">
        <BaseElSelect v-model="selectActivity" placeholder="選擇活動">
          <BaseElSelectOption
            v-for="act in activitys"
            :key="act.id"
            :label="act.name"
            :value="act.id"
          />
        </BaseElSelect>
      </BaseElFormItem>
    </template>

    <template v-if="selectOption === 'ecommercePage'">
      <!-- 頁面選擇 -->
      <BaseElFormItem :prop="`${objKey}.ecommercePageId`">
        <BaseElSelect v-model="selectPage" placeholder="選擇頁面">
          <BaseElSelectOption
            v-for="page in pages"
            :key="page.id"
            :label="page.title"
            :value="page.id"
          />
        </BaseElSelect>
      </BaseElFormItem>
    </template>
  </div>
</template>

<script>
import { clone } from 'lodash'

export default {
  name: 'NavOptions',
  props: ['navConfigData', 'activitys', 'pages', 'objKey'],
  data: () => ({
    selectOption: null,
    options: [
      {
        label: '活動',
        type: 'ecommerceActivity',
      },
      {
        label: '其他連結',
        type: 'link',
      },
      {
        label: '其他頁面',
        type: 'ecommercePage',
      },
    ],
    linkName: null,
    linkUrl: null,
    selectActivity: null,
    selectPage: null,
  }),
  computed: {

    navData () {
      if (!this.selectOption) return null
      const type = this.selectOption
      const data = {
        type,
      }
      if (type === 'link') {
        data.name = this.linkName
        data.url = this.linkUrl
      }
      if (type === 'ecommercePage') data.ecommercePageId = this.selectPage
      if (type === 'ecommerceActivity') { data.ecommerceActivityId = this.selectActivity }
      return data
    },
  },
  watch: {
    navData (data) {
      this.$emit('update', data)
    },
  },
  mounted () {
    this.syncConfigData()
  },
  methods: {
    syncConfigData () {
      const data = clone(this.navConfigData)
      const type = data.type

      this.selectOption = type
      if (type === 'link') {
        this.linkName = data.name
        this.linkUrl = data.url
      }
      if (type === 'ecommercePage') this.selectPage = data.ecommercePageId
      if (type === 'ecommerceActivity') this.selectActivity = data.ecommerceActivityId
    },
  },
}
</script>

<style lang="postcss" scoped>

</style>
